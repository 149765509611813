import apolloClient from 'common/services/apolloClient';
import AuthUser from 'assets/graphql/queries/MalevaAuthUser.graphql';

const ApolloClient = apolloClient();

const actions = {
  async getProfile({ commit }) {
    const response = await ApolloClient.query({
      query: AuthUser,
      fetchPolicy: 'network-only',
    });
    const { maleva_auth_user } = response.data;
    if (maleva_auth_user) {
      commit('SET_PROFILE', maleva_auth_user);
    }
  },
};

export default actions;
