import Vue from 'vue';
import Vuex from 'vuex';

import userModule from './user/index';

function createStore() {
  return new Vuex.Store({
    modules: {
      user: {
        namespaced: true,
        ...userModule,
      },
    },
    state: {
      showUnloggedModal: false,
      showSuccessRegisterModal: false,
      redirect: '',
    },
    mutations: {
      SET_UNLOGGED_USER_MODAL(state, value) {
        state.showUnloggedModal = value;
      },
      SET_SUCCESS_REGISTER_MODAL(state, value) {
        state.showSuccessRegisterModal = value;
      },
      SET_REDIRECT(state, value) {
        state.redirect = value;
      },
      CLEAR_REDIRECT(state) {
        state.redirect = '';
      },
    },
    actions: {},
    getters: {},
  });
}

Vue.use(Vuex);

export default createStore;
