import 'common/scss/common-sass.scss';

import Vue from 'vue';
import VueRouter from 'vue-router';
import createRouter from 'router';
import createStore from 'store';
import VueApollo from 'vue-apollo';

import createApolloClient from 'common/services/apolloClient';
import sentryInit from "common/plugins/sentry";

import App from 'App.vue';


import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.use(VueRouter);
Vue.use(VueApollo);

const router = createRouter();
const store = createStore();

const apolloClient = createApolloClient();
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

const sentry_key = "8607bb04b41f4ba5bcef28c9b8e2351f";
const sentry_id = "8";
sentryInit(sentry_key,sentry_id, Vue, router );

AOS.init();

const app = new Vue({
  el: '#app',
  apolloProvider,
  router,
  store,
  render: h => h(App),
});

export default app;
