import Router from 'vue-router';
import AuthUser from 'assets/graphql/queries/MalevaAuthUser.graphql';
import createApolloClient from 'common/services/apolloClient';
const apolloClient = createApolloClient();

const base = process.env.NODE_ENV === 'production' ? '/' : 'maleva';

export default function createRouter() {
  const routerOptions = {
    base: base,
    mode: 'history',
    routes: [
      {
        path: '/cuenta',
        component: () =>
          import(
            /* webpackChunkName: "home-session-view" */ 'views/HomeSessionView/HomeSessionView.vue'
          ),
        children: [
          {
            path: '/',
            name: 'homeSession',
            component: () =>
              import(
                /* webpackChunkName: "main-hero" */ 'components/HomeSession/MainHero/MainHero.vue'
              ),
          },
          {
            path: '/',
            component: () =>
              import(
                /* webpackChunkName: "container-view" */ 'components/HomeSession/UserActionWrapper/UserActionWrapper.vue'
              ),
            children: [
              {
                path: 'iniciar-sesion',
                name: 'login',
                component: () =>
                  import(
                    /* webpackChunkName: "user-login" */ 'components/HomeSession/UserLogin/UserLogin.vue'
                  ),
              },
              {
                path: 'registrar-usuario',
                name: 'registration',
                component: () =>
                  import(
                    /* webpackChunkName: "user-registration" */ 'components/HomeSession/UserRegistration/UserRegistration.vue'
                  ),
              },
            ],
          },
          {
            path: 'recuperar-contrasena',
            name: 'forgotPassword',
            component: () =>
              import(
                /* webpackChunkName: "user-forgot-password" */ 'components/HomeSession/UserForgotPassword/UserForgotPassword.vue'
              ),
          },
          {
            path: 'cambiar-contrasena/:hash',
            name: 'resetPassword',
            component: () =>
              import(
                /* webpackChunkName: "user-reset-password" */ 'components/HomeSession/UserResetPassword/UserResetPassword.vue'
              ),
          },
        ],
      },
      {
        path: '/',
        component: () =>
          import(
            /* webpackChunkName: "layout-wrapper" */ 'components/layout/LayoutWrapper/LayoutWrapper.vue'
          ),
        children: [
          {
            path: '/',
            name: 'home',
            component: () =>
              import(/* webpackChunkName: "home-view" */ 'views/HomeView'),
          },
          {
            path: '/experiencia/:activitySlug',
            name: 'experiencia',
            component: () =>
              import(
                /* webpackChunkName: "activity-view" */ 'views/ActivityView'
              ),
          },
          {
            path: '/',
            component: () =>
              import(
                /* webpackChunkName: "profile-view" */ 'views/ProfileView/ProfileView.vue'
              ),
            children: [
              {
                path: 'mis-reservas',
                name: 'profileReservations',
                component: () =>
                  import(
                    /* webpackChunkName: "profile-reservations" */ 'components/Profile/ProfileReservations/ProfileReservations.vue'
                  ),
              },
              {
                path: 'mis-datos',
                name: 'profileData',
                component: () =>
                  import(
                    /* webpackChunkName: "profile-data" */ 'components/Profile/ProfileData/ProfileData.vue'
                  ),
              },
              {
                path: 'mis-intereses',
                name: 'profileInterests',
                component: () =>
                  import(
                    /* webpackChunkName: "profile-interests" */ 'components/Profile/ProfileInterests/ProfileInterests.vue'
                  ),
              },
            ],
          },
          {
            path: '/faq',
            name: 'faq',
            component: () =>
              import(
                /* webpackChunkName: "faq-view" */ 'views/FaqView/FaqView.vue'
              ),
          },
          {
            path: '/contacto',
            name: 'contact',
            component: () =>
              import(
                /* webpackChunkName: "contact-view" */ 'views/ContactView/ContactView.vue'
              ),
          },
          {
            path: '/terminos',
            name: 'terms',
            component: () =>
              import(
                /* webpackChunkName: "terms-view" */ 'views/TermsView/TermsView.vue'
              ),
          },
          {
            path: '/pagina-no-encontrada',
            name: '404',
            component: () =>
              import(
                /* webpackChunkName: "not-found-view" */ 'views/NotFoundView/NotFoundView.vue'
              ),
          },
          {
            path: '/error-interno',
            name: '500',
            component: () =>
              import(
                /* webpackChunkName: "internal-error-view" */ 'views/InternalErrorView/InternalErrorView.vue'
              ),
          },
        ],
      },
      {
        path: '/',
        component: () =>
          import(
            /* webpackChunkName: "secure-layout-wrapper" */ 'components/layout/SecureLayoutWrapper/SecureLayoutWrapper.vue'
          ),
        children: [
          {
            path: '/checkout/:hash',
            name: 'checkout',
            component: () =>
              import(
                /* webpackChunkName: "checkout-view" */ 'views/CheckoutView/CheckoutView.vue'
              ),
          },
          {
            path: '/order-status/:id',
            name: 'orderStatus',
            component: () =>
              import(
                /* webpackChunkName: "order-status" */ 'components/Checkout/OrderStatus/OrderStatus.vue'
              ),
          },
        ],
      },
      {
        path: '*',
        component: () =>
          import(
            /* webpackChunkName: "layout-wrapper" */ 'components/layout/LayoutWrapper/LayoutWrapper.vue'
          ),
        children: [
          {
            path: '*',
            component: () =>
              import(
                /* webpackChunkName: "not-found-view" */ 'views/NotFoundView/NotFoundView.vue'
              ),
          },
        ],
      },
    ],

    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  };

  const router = new Router(routerOptions);

  router.beforeEach(async (to, from, next) => {
    const public_routes = [
      'terms',
      'homeSession',
      'login',
      'registration',
      'forgotPassword',
      'resetPassword',
    ];
    if (public_routes.includes(to.name)) {
      next();
      return;
    }

    try {
      let malevaUser = await apolloClient.query({
        query: AuthUser,
        fetchPolicy: 'network-only',
      });
      if (malevaUser.data.maleva_auth_user !== null) {
        next();
      } else {
        throw new Error('Not logged in');
      }
    } catch (error) {
      if (to.name !== 'experiencia') {
        next({ name: 'homeSession' });
      } else {
        router.app.$store.commit('SET_UNLOGGED_USER_MODAL', true);
        next();
      }
    }
  });

  return router;
}
