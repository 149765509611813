const state = {
  profile: {
    id: undefined,
    first_name: '',
    last_name: '',
    document: '',
    birthdate: '',
    phone: '',
    email: '',
    gender: '',
    profile_image: '',
  },
};

export default state;
