import { render, staticRenderFns } from "./MvModal.vue.html?vue&type=template&id=0ebb648b&"
import script from "./MvModal.component.js?vue&type=script&lang=vueJs&"
export * from "./MvModal.component.js?vue&type=script&lang=vueJs&"
import style0 from "./MvModal.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports