import Vue from 'vue';
import Component from 'vue-class-component';
import MvModal from 'components/common/MvModal/MvModal.vue';

import Succes from 'assets/images/SuccessRegister.png';

@Component({
  components: {
    MvModal,
  },
})
export default class SuccessRegisterModalComponent extends Vue {
  successBackground = Succes;

  closeModal() {
    this.$store.commit('SET_SUCCESS_REGISTER_MODAL', false);
  }
}
