import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classIcon: {
      default: '--black',
    },
    disableClose: {
      default: false,
    },
  },
})
export default class MvModalComponent extends Vue {
  mounted() {
    if (this.show) {
      this.removeScroll();
    }
  }

  updated() {
    if (!this.show) {
      this.addScroll();
    } else {
      this.removeScroll();
    }
  }

  removeScroll() {
    /**
     * Don't change the order or it won't work
     */
    window.document.body.style.top = `-${window.scrollY}px`;
    window.document.body.style.position = 'fixed';
  }

  addScroll() {
    const scrollY = window.document.body.style.top;
    window.document.body.style.position = '';
    window.document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  }

  closeModal() {
    if (!this.disableClose) {
      this.$emit('close');
    }
  }
}
