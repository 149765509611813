<template>
  <div class="MalevaApp">
    <router-view></router-view>
    <unlogged-user-modal />
    <success-register-modal />
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import UnloggedUserModal from './components/HomeSession/UnloggedUserModal/UnloggedUserModal.vue';
  import SuccessRegisterModal from './components/HomeSession/SuccessRegisterModal/SuccessRegisterModal.vue';

  @Component({
    components: {
      UnloggedUserModal,
      SuccessRegisterModal,
    },
  })
  export default class App extends Vue {}
</script>

<style lang="scss">
  @import '~assets/styles/index.scss';

  .MalevaApp {
    position: relative;
  }
</style>
