import Vue from 'vue';
import Component from 'vue-class-component';
import MvModal from 'components/common/MvModal/MvModal.vue';

@Component({
  components: {
    MvModal,
  },
})
export default class UnloggedUserModalComponent extends Vue {
  redirect(routeName) {
    const redirect = this.$route.fullPath;
    this.$store.commit('SET_REDIRECT', redirect);
    this.$router.push({ name: routeName });
    this.$store.commit('SET_UNLOGGED_USER_MODAL', false);
  }
  closeModal() {}
}
